<template>
	<div class="m-5">
		
		<h3 class="text-center mt-3 mb-3 font-weight-bold">福利卡</h3>
		
		<template v-if="isLoad">
			<template v-if="isCheck">
				<div class="row">
					<div class="col-sm-12">
						<div id="card_qr" style="display: flex; justify-content: center;"></div>
						<div class="text-center mt-3">打开支付宝扫一扫领取福利卡</div>
						<div class="text-center mt-1">或截屏后，打开支付宝扫一扫识别二维码</div>
						<div class="text-center mt-1" style="font-size: 16px;">{{dept_name}},客服电话:010-53604111</div>
					</div>
				</div>
			</template>
			<template v-if="!isCheck">
				<div class="text-center text-danger mt-3">{{err}}</div>
				<div class="text-center mt-1" style="font-size: 16px;">{{dept_name}},客服电话:010-53604111</div>
			</template>
		</template>
		
		<template v-if="!isLoad">
			<div class="text-center mt-3">载入中...</div>
		</template>
		
		
		
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	
	export default {
		data() {
			return {
				title: '福利卡',
				token: '',
				
				url: 'http://fulipay.net/',
				id: 0,
				no: '',
				
				isLoad: false,
				isCheck: false,
				err: '',
				
				dept_name: '',
			}
		},
	
		components: {
			// VPagination
		},
	
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//3.取得参数 
			console.log('--- this.$route -> ',this.$route);
			self.id = this.$route.query.id;
			self.no = this.$route.query.no;
			
			console.log('--- id, no -> ',self.id, self.no);
			if(!self.id || self.id == 0){
				self.isLoad = true;
				self.isCheck = false;
				self.err = '链接错误'
			}
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			self.init((res)=>{
				if(res){
					setTimeout(()=>{
						self.initQrcode();
					},1000)
				}
			})
		},
		methods: {
			init(callback) {
				let self = this;
			
				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'checkGiftCard', {
						params: {
							id: self.id,
							no: self.no,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.isLoad = true;
						
						if (data.code == 200) {
							self.dept_name = data.data.dept_name;
							
							//判断电子卡是否有效
							if(data.data.result_code == 'success'){
								self.isCheck = true;
								if (typeof callback == 'function') {
									callback(true)
								}
							}
							else{
								// self.$toasted.error(data.data.result_msg, {
								// 	position: 'top-center',
								// }).goAway(2000)
								
								self.err = data.data.result_msg;
							}
						} 
						else if (data.code == 107) {
							 self.err = '链接错误';
							 return;
						}
						else if (data.code == 403) {
							 self.$router.push({ name: '403'});
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} 
						else {
							// self.$toasted.error(data.message, {
							// 	position: 'top-center',
							// }).goAway(2000)
							self.err = data.message;
						}
					});
				//END
			
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
	
				self.init();
			},
	
	
			onstatechange(state) {
				console.log('--- state  -> ', state)
			},
			
			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initSaffList();
			},
			
			back(){
				let self = this;
				self.$router.push({
					path: 'institutions',
					params: {
						outlet_id: self.outlet_id
					}
				})
			},
			
			initQrcode(){
				let self = this;
				console.log('---- self.giftcard ->',self.giftcard)
				
				$('#card_qr').empty();
				
				//http://fulipay.net/142#6400F9D19E
				let url = self.no == '' ? self.url+self.id : self.url+self.id+"#"+self.no;
				console.log('---- url->',url)
				
				new QRCode('card_qr', {
					text: url, // 需要转换为二维码的内容
					width: 256,
					height: 256,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			}
	
		},
	}
</script>

<style>
</style>